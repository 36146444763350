var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"overflow-y":"auto"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.spots),function(spot){return _c('v-col',{key:spot.uuid,attrs:{"cols":_vm.control.config.cols}},[_c('v-card',{attrs:{"elevation":"0","color":"transparent"}},[_c('div',{staticClass:"d-flex",staticStyle:{"user-select":"none"},style:(_vm.cardStyle(spot))},[_c('span',{style:({
              color:
                spot.alarm == 0 || spot.alarm == 7 || spot.alarm == 8
                  ? _vm.control.style.color
                  : _vm.control.config.alarmColor,
            }),domProps:{"textContent":_vm._s(spot.name)}}),_c('v-spacer'),_c('span',{style:({
              color:
                spot.alarm == 0 || spot.alarm == 7 || spot.alarm == 8
                  ? _vm.control.style.color
                  : _vm.control.config.alarmColor,
            }),domProps:{"textContent":_vm._s(spot.value)}}),_c('span',{style:({
              color:
                spot.alarm == 0 || spot.alarm == 7 || spot.alarm == 8
                  ? _vm.control.style.color
                  : _vm.control.config.alarmColor,
            }),domProps:{"textContent":_vm._s(spot.unit)}})],1)])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }